import Switch from '../../../common/switch/Switch'
import Input from '../../../common/fields/Input'
import { useTranslation } from 'react-i18next'

const Client = ({ data, handleChange, error, setUploadError, uploadError, id, changes }) => {
  const { t } = useTranslation()
  const selectProtocol = (protocol) => {
    handleChange('configs.security.host.radius.protocol', protocol)
  }
  return (
    <form className="w-full flex flex-col space-y-4">
      <Switch
        title={`${t('HOST_AUTH_REQUIRED')} ${
          changes['configs.security.host.auth_required'] ? '●' : ''
        }`}
        value={data?.auth_required}
        description={t('HOST_AUTH_REQUIRED_DESC')}
        onChange={(v) => {
          handleChange('configs.security.host.auth_required', v)
          selectProtocol('plain')
        }}
      />
      {data?.auth_required && (
        <div className="flex flex-col space-y-2 ">
          <div className=" border p-5 space-y-3">
            <label className="p-2">{`${t('SELECT_EAP_PROTOCOL')} ${
              changes['configs.security.host.radius.protocol'] ? '●' : ''
            }`}</label>
            <hr />
            <div className="flex w-full items-center justify-between px-12">
              <div
                className="p-2 px-4 hover:bg-gray-200 rounded-lg space-x-2 cursor-pointer"
                onClick={() => selectProtocol('plain')}
              >
                <input
                  type="radio"
                  name="protocol"
                  value="plain"
                  checked={data?.radius.protocol === 'plain'}
                  onChange={() => {}}
                />
                <span>{t('PLAIN')}</span>
              </div>
              <div
                className="p-2 px-4 hover:bg-gray-200 rounded-lg space-x-2 cursor-pointer"
                onClick={() => selectProtocol('radsec')}
              >
                <input
                  type="radio"
                  name="protocol"
                  value="radsec"
                  checked={data?.radius.protocol === 'radsec'}
                  onChange={() => {}}
                />
                <span>{t('RADSEC')}</span>
              </div>
            </div>
          </div>
          <Input
            label={`${t('IP_RADIUS')} ${changes['configs.security.host.radius.ip'] ? '●' : ''}`}
            value={data?.radius.ip}
            onChange={(v) => handleChange('configs.security.host.radius.ip', v)}
            error={error?.security?.radius_ip_host}
          />
          <Input
            label={`${t('PORT_RADIUS')} ${changes['configs.security.host.radius.port'] ? '●' : ''}`}
            value={data?.radius.port}
            onChange={(v) => handleChange('configs.security.host.radius.port', parseInt(v))}
            error={error?.security?.radius_port_host}
          />
          <Input
            label={`${t('SECRET_RADIUS')} ${
              changes['configs.security.host.radius.secret'] ? '●' : ''
            }`}
            value={data?.radius.secret}
            type={'password'}
            hasVisable={true}
            onChange={(v) => handleChange('configs.security.host.radius.secret', v)}
            error={error?.security?.radius_secret_host}
          />
        </div>
      )}
      <hr />
      <Switch
        title={`${t('ENFORCE_POLICIES')} ${
          changes['configs.security.host.enforce_policies'] ? '●' : ''
        }`}
        value={data?.enforce_policies}
        description={t('ENFORCE_POLICIES_DESC')}
        onChange={(v) => handleChange('configs.security.host.enforce_policies', v)}
        disabled={true}
      />
    </form>
  )
}

export default Client
