import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import DeleteConfirmation from '../modals/DeleteConfirmation'
import DownloadIcon from '@mui/icons-material/Download'
import { useEffect, useRef, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import TaskIcon from '@mui/icons-material/Task'
import { FileConvertSize } from '../../helpers/string'
import { useTranslation } from 'react-i18next'

export default function FileInput({
  fileName,
  size,
  md5,
  sha1,
  handleFile,
  error,
  onDownload,
  onDelete,
  py,
  little
}) {
  const { t } = useTranslation()
  const drop = useRef()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [dragActive, setDragActive] = useState(false)
  const [listener, setListener] = useState(false)
  useEffect(() => {
    if (!fileName && !listener) {
      addDragEvent()
      setListener(true)
    } else if (fileName && listener) {
      removeDragEvent()
      setListener(false)
    }
  }, [fileName])
  const addDragEvent = () => {
    drop.current.addEventListener('dragover', handleDragOver)
    drop.current.addEventListener('dragleave', handleDragLeave)
    drop.current.addEventListener('drop', handleDrop)
  }
  const removeDragEvent = () => {
    if (drop.current) {
      drop.current.removeEventListener('dragover', handleDragOver)
      drop.current.removeEventListener('dragleave', handleDragLeave)
      drop.current.removeEventListener('drop', handleDrop)
    }
  }
  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(true)
  }
  const handleDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
  }
  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const { files } = e.dataTransfer || e.target
    const file = files[0]
    setDragActive(false)
    handleFile(file)
  }
  if (!fileName) {
    return (
      <div className="flex flex-col w-full">
        <div
          ref={drop}
          className={` ${
            py ? `py-${py}` : 'py-10'
          } mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 ${
            dragActive ? 'border-blue-500 bg-blue-100' : error ? '!border-red-500' : ''
          }`}
        >
          <div className="text-center w-full px-8">
            <CloudUploadIcon fontSize="large" className="text-blue-500" />
            <div className="mt-4 flex text-sm leading-6 text-gray-600 items-center justify-center">
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer rounded-md bg-white font-semibold text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-600 focus-within:ring-offset-2 hover:text-blue-500"
              >
                <span>{t('CHOOSE_FILE')}</span>
                <input
                  onChange={handleDrop}
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  className="sr-only"
                />
              </label>
              <p className="pl-1">{t('DRAG_DROP')}</p>
            </div>
            <p className="text-xs leading-5 text-gray-600 mb-4">{t('UPTO300MB')}</p>
          </div>
        </div>
        {error && <span className="mt-1 w-full text-sm text-red-500">{error}</span>}
      </div>
    )
  } else {
    return (
      <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-4 ">
        {showDeleteModal && (
          <DeleteConfirmation
            action="file"
            handleClose={() => setShowDeleteModal(false)}
            handleConfirm={() => {
              setShowDeleteModal(false)
              onDelete()
            }}
          />
        )}
        <div className="text-center w-full ">
          <div className="w-full rounded-md border border-blue-500 bg-blue-100 text-gray-700 flex flex-col py-2 px-4 justify-between items-center">
            <div className="flex justify-between items-center w-full">
              <div className={`flex space-x-2 ${little ? 'max-w-[300px]' : ''}`}>
                <TaskIcon />
                <span className={`truncate ${little ? 'max-w-[40%]' : ''}`}>{fileName}</span>
                <div className="bg-green-600 px-2 text-white rounded-full py-0.5 text-xs flex items-center justify-center">
                  {FileConvertSize(size)}
                </div>
              </div>
              <div className="flex space-x-2 items-center justify-center">
                {/*<button
                  onClick={() => setShowDeleteModal(true)}
                  className="rounded-full flex items-center p-2 text-gray-700  hover:text-red-500 dark:text-white dark:hover:text-red-500 border border-blue-500 bg-gray-100 shadow-md"
                >
                  <DeleteIcon />
                </button>
                  {!little && (
                  <button
                   onClick={onDownload}
                   className="rounded-full flex items-center p-2 text-gray-700  hover:text-blue-500 dark:text-white  border border-blue-500 bg-gray-100 shadow-md"
                  >
                  </div> <DownloadIcon />
                   </button>
                  )}*/}
              </div>
            </div>
            {(sha1 || md5) && (
              <div className='className="px-2 text-gray-500 rounded-full py-0.5 text-xs flex space-x-4'>
                <span>MD5: {md5}</span>
                <span>SHA1: {sha1}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}
