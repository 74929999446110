import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import Explain from '../Tooltip/Explain'
import { useDispatch, useSelector } from 'react-redux'
import UploadIcon from '@mui/icons-material/Upload'

import { FileConvertSize } from '../../helpers/string'
import { uploadCertificate } from '../../store/certificate/uploadCertificate'

const UploadCert = ({ setUploadError, uploadError, app, id }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const uploadState = useSelector((state) => state.uploadCertificate)
  const [certFile, setCertFile] = useState({ file: null, fileName: '', size: null })
  const [caFile, setCaFile] = useState({ file: null, fileName: '', size: null })

  const handleFile = (file, set) => {
    if (file) {
      set((prev) => ({
        ...prev,
        fileName: file.name,
        file: file,
        size: file.size
      }))
    }
  }

  const handleUpload = () => {
    if (!certFile.file) {
      setUploadError((prev) => ({
        ...prev,
        [app]: {
          ...prev[app],
          cert: t('CERTIFICATE_FILE_REQUIRED')
        }
      }))
    }

    if (!caFile.file) {
      setUploadError((prev) => ({
        ...prev,
        [app]: {
          ...prev[app],
          ca: t('CA_FILE_REQUIRED')
        }
      }))
    }
    if (certFile.file && caFile.file) {
      const reader = new FileReader()
      reader.readAsText(certFile.file)
      reader.onload = (evt) => {
        const cert = evt.target.result
        const readerCa = new FileReader()
        readerCa.readAsText(caFile.file)
        readerCa.onload = (evt) => {
          const ca = evt.target.result
          dispatch(
            uploadCertificate({
              id: id,
              cert: cert,
              ca: ca,
              app: app
            })
          )
        }
      }
    }
  }

  return (
    <div className="border p-5 space-y-3">
      <div className="text-sm font-medium flex space-x-2">
        <p>{t('SELECT_CERTIFICATE_FILE')}</p>
        <Explain
          description={app === 'dot1x' ? t('UPLOAD_DOT1X_CERT_DESC') : t('UPLOAD_TLS_CERT_DESC')}
        />
      </div>
      <div className="flex flex-row items-center">
        <input
          type="file"
          id={`cert-${id}-${app}`}
          onChange={(e) => {
            handleFile(e.target.files[0], setCertFile)
            setUploadError((prev) => {
              const newData = { ...prev }
              delete newData[[app]].cert
              return newData
            })
          }}
          hidden
        />
        <label
          htmlFor={`cert-${id}-${app}`}
          className="block text-sm py-2 px-4 whitespace-nowrap rounded-l-md border-0 font-semibold bg-blue-50 text-blue-700 hover:bg-blue-100 cursor-pointer"
        >
          {t('CHOOSE_FILE')}
        </label>
        <label
          className={`text-sm p-2 text-slate-500 border ${
            uploadError?.cert ? 'border-red-600' : 'border-gray-200'
          } rounded-r-md h-9 w-full flex justify-between`}
        >
          <p className="truncate w-40"> {certFile.fileName}</p>

          {certFile.size && (
            <div className="bg-green-600 px-2 text-white rounded-full py-0.5 text-xs flex items-center justify-center truncate">
              {FileConvertSize(certFile.size)}
            </div>
          )}
        </label>
      </div>
      {uploadError?.cert && <p className="text-sm text-red-500">{uploadError?.cert}</p>}
      <div className="text-sm font-medium flex space-x-2">
        <p>{t('SELECT_CA_FILE')}</p>
        <Explain description={t('UPLOAD_DOT1X_CA_DESC')} />
      </div>
      <div className="flex flex-row items-center">
        <input
          type="file"
          id={`ca-${id}-${app}`}
          onChange={(e) => {
            handleFile(e.target.files[0], setCaFile)
            setUploadError((prev) => {
              const newData = { ...prev }
              delete newData[[app]].ca
              return newData
            })
          }}
          hidden
        />
        <label
          htmlFor={`ca-${id}-${app}`}
          className="block text-sm py-2 px-4 whitespace-nowrap rounded-l-md border-0 font-semibold bg-blue-50 text-blue-700 hover:bg-blue-100 cursor-pointer"
        >
          {t('CHOOSE_FILE')}
        </label>
        <label
          className={`text-sm p-2 text-slate-500 border ${
            uploadError?.ca ? 'border-red-600' : 'border-gray-200'
          } rounded-r-md h-9 w-full flex justify-between`}
        >
          <p className="truncate w-40"> {caFile.fileName}</p>

          {caFile.size && (
            <div className="bg-green-600 px-2 text-white rounded-full py-0.5 text-xs flex items-center justify-center truncate">
              {FileConvertSize(caFile.size)}
            </div>
          )}
        </label>
      </div>
      {uploadError?.ca && <p className="text-sm text-red-500">{uploadError?.ca}</p>}
      <button
        type="button"
        disabled={uploadState.loading}
        onClick={handleUpload}
        className={` w-full flex items-center justify-center py-2 px-4 border border-transparent shadow-sm font-medium rounded-md text-white  ${
          uploadState.loading ? ' cursor-not-allowed bg-blue-400' : 'bg-blue-500 hover:bg-blue-400'
        } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-300 space-x-2`}
      >
        <p>{t('UPLOAD_CERT')}</p>
        {uploadState.loading ? (
          <div className="relative flex items-center justify-center">
            <div className=" animate-spin flex items-center justify-center rounded-full p-3 bg-gradient-to-tr from-blue-500 to-white" />
            <div className="absolute p-2 bg-blue-400 rounded-full" />
          </div>
        ) : (
          <UploadIcon />
        )}
      </button>
    </div>
  )
}

export default UploadCert
