exports.capitalizeFirstLetter = (string) =>
  string && string.length ? string.charAt(0).toUpperCase() + string.slice(1) : ''

exports.IpValidator = (ip) =>
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/gm.test(
    ip
  )
exports.FileConvertSize = (aSize) => {
  aSize = Math.abs(parseInt(aSize, 10))
  var def = [
    [1, 'o'],
    [1000, 'ko'],
    [1000 * 1000, 'Mo'],
    [1000 * 1000 * 1000, 'Go'],
    [1000 * 1000 * 1000 * 1000, 'To']
  ]
  for (var i = 0; i < def.length; i++) {
    if (aSize < def[i][0]) return (aSize / def[i - 1][0]).toFixed(2) + ' ' + def[i - 1][1]
  }
}
exports.IsValidVersion = (v) => /^\d{1,2}\.\d{1,2}\.\d{1,2}$/.test(v)

exports.PortValidator = (port) => {
  const portNumber = Number(port)
  return Number.isInteger(portNumber) && portNumber >= 1 && portNumber <= 65535
}

exports.formatDataRate = (mbps) => {
  mbps = parseFloat(mbps)
  const units = ['b/s', 'Kb/s', 'Mb/s', 'Gb/s', 'Tb/s']
  let rate = mbps * 1_000_000 // Convert Mb/s to b/s
  let unitIndex = 0

  // Adjust rate and find appropriate unit
  while (rate >= 1000 && unitIndex < units.length - 1) {
    rate /= 1000
    unitIndex++
  }
  let str = `${rate.toFixed(2)} ${units[unitIndex]}`
  console.log(str.substring(0, str.indexOf(' ')))
  console.log(str.substring(str.indexOf(' ') + 1))

  // Format the result to 2 decimal places if needed
  return `${rate.toFixed(2)} ${units[unitIndex]}`
}

exports.getAfterSpace = (str) => {
  return str.substring(str.indexOf(' ') + 1)
}

exports.getBeforeSpace = (str) => {
  return str.substring(0, str.indexOf(' '))
}
