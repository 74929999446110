import CircularProgressBar from '../../common/widgets/CircularProgressBar'
import VerifiedIcon from '@mui/icons-material/Verified'
import DownloadIcon from '@mui/icons-material/Download'
import UploadIcon from '@mui/icons-material/Upload'

import {
  capitalizeFirstLetter,
  formatDataRate,
  getAfterSpace,
  getBeforeSpace
} from '../../helpers/string'
import { formatDateTime } from '../../helpers/date'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

import {
  initiateSocket,
  subscribeToDeviceMetrics,
  disconnectSocket,
  sendMessage
} from '../../utils/socketAuditing'
import LineDebitDevice from '../Dashboard/LineDebitDevice'

const HeaderDeviceDetails = ({ device }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [trigger, setTrigger] = useState(false)
  const [metricsWS, setMetricsWS] = useState({})
  useEffect(() => {
    initiateSocket()

    subscribeToDeviceMetrics(device.data.device_uuid, (data) => {
      setMetricsWS(data.data)
    })
    sendMessage('join/metrics', device.data.device_uuid)

    return () => {
      disconnectSocket()
    }
  }, [])

  useEffect(() => {
    setTrigger(!trigger)
  }, [metricsWS])
  return (
    <>
      {device.data.device_hardware_id ? (
        <div className="w-full bg-white h-full rounded-lg relative border border-gray-200 shadow-md">
          <div className="bg-pattern-left absolute md:w-1/2 w-full h-full opacity-60 -z-5 left-0 bottom-0" />
          <div className="bg-pattern-right absolute md:w-1/2 w-0 h-full opacity-60 -z-5 right-0 bottom-0" />
          <div className="flex-auto h-full">
            <div className="grid p-2 items-center grid-cols-1 lg:grid-cols-12 h-full ">
              <div className="grid items-center grid-rows-3 h-full col-span-3">
                <div className="flex items-center space-x-1">
                  <div
                    onClick={() => navigate(-1)}
                    className="hover:opacity-50 cursor-pointer z-10"
                  >
                    <h4>{t('DEVICES')}</h4>
                  </div>
                  <h4>&gt;</h4>
                  <h4 className="opacity-90 bg-gray-100 p-1 px-2 rounded-full font-semibold ">
                    {capitalizeFirstLetter(
                      device.data?.informations[0]?.configs?.network?.hostname ||
                        device.data.hostname ||
                        'N/A'
                    )}
                  </h4>
                </div>
                <div className="flex">
                  <p className="mb-0 text-slate-500 dark:text-white">{t('PROVISIONED')} :</p>
                  <p className="text-slate-500 dark:text-white">
                    {device.data.provisioning_last_date
                      ? formatDateTime(device.data.provisioning_last_date)
                      : 'N/A'}
                  </p>
                </div>
                <div className="flex items-start">
                  <span className="text-slate-500 dark:text-white">{t('STATUS')} :</span>
                  <div
                    data-tip=""
                    data-for={`status-${device.data.device_uuid}`}
                    data-class="react-component-tooltip"
                    className="flex"
                  >
                    {Object.keys(metricsWS).length == 0 ? (
                      <div className="flex space-x-2 items-center">
                        <h3 className="text-slate-500 dark:text-white">{t('OFFLINE')}</h3>
                        <VerifiedIcon className="text-gray-400" fontSize="small" />
                      </div>
                    ) : metricsWS.tx_mbps > 0 || metricsWS.rx_mbps > 0 ? (
                      <div className="flex space-x-2 items-center">
                        <h3 className="text-slate-500 dark:text-white">{t('CONNECTED')}</h3>
                        <VerifiedIcon className="text-green-600" fontSize="small" />
                      </div>
                    ) : (
                      <div className="flex space-x-2 items-center">
                        <h3 className="text-slate-500 dark:text-white">{t('IDDLE')}</h3>
                        <VerifiedIcon className="text-orange-400" fontSize="small" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-span-6">
                <div className="flex items-center justify-center xl:space-x-5 lg:space-x-0">
                  <CircularProgressBar
                    selectedValue={metricsWS.cpu}
                    radius={45}
                    withGradient
                    valueFontSize={14}
                    labelFontSize={12}
                    anticlockwise
                    label="CPU"
                  />
                  <CircularProgressBar
                    selectedValue={metricsWS.temperature}
                    radius={45}
                    withGradient
                    valueFontSize={14}
                    labelFontSize={12}
                    anticlockwise
                    label={t('TEMPERATURE')}
                    unity="°C"
                  />
                  <CircularProgressBar
                    radius={45}
                    valueFontSize={14}
                    labelFontSize={12}
                    selectedValue={metricsWS.ram}
                    withGradient
                    activeStrokeColor="#cc6600"
                    anticlockwise
                    label="RAM"
                  />
                  <CircularProgressBar
                    selectedValue={metricsWS.swap}
                    radius={45}
                    valueFontSize={14}
                    labelFontSize={12}
                    activeStrokeColor="#cc6600"
                    withGradient
                    anticlockwise
                    label="SWAP"
                  />
                  <CircularProgressBar
                    selectedValue={metricsWS.storage}
                    radius={45}
                    valueFontSize={14}
                    labelFontSize={12}
                    activeStrokeColor="#cc6600"
                    withGradient
                    anticlockwise
                    label={t('STORAGE')}
                  />
                </div>
              </div>
              <div className="grid grid-cols-5 col-span-3">
                <div className="relative col-span-3 w-full">
                  <div className="lg:bg-gradient-to-r from-white absolute h-full w-full" />
                  <LineDebitDevice
                    rx={metricsWS.rx_mbps ? metricsWS.rx_mbps : 0}
                    tx={metricsWS.tx_mbps ? metricsWS.tx_mbps : 0}
                    trigger={trigger}
                    options={{
                      responsive: true,
                      maintainAspectRatio: true,
                      animation: {
                        duration: 0
                      },
                      plugins: {
                        legend: {
                          display: false
                        },
                        tooltips: {
                          enabled: false
                        }
                      },
                      scales: {
                        x: {
                          display: false
                        },
                        y: {
                          display: false,
                          min: 0,
                          max: 1400,
                          stepSize: 200
                        }
                      }
                    }}
                  />
                </div>
                <div className="flex flex-col col-span-2 items-center h-full justify-center text-gray-500">
                  <p className="w-32 ml-10 text-s">
                    &nbsp;{' '}
                    {metricsWS.tx_mbps ? getBeforeSpace(formatDataRate(metricsWS.tx_mbps)) : '0'}
                    <i className="text-xs">
                      &nbsp;{' '}
                      {metricsWS.tx_mbps
                        ? getAfterSpace(formatDataRate(metricsWS.tx_mbps))
                        : 'Mb/s'}{' '}
                      <UploadIcon className="text-blue-500 -ml-1" fontSize="xs" />
                    </i>
                  </p>
                  <p className="w-32 ml-10 text-s">
                    &nbsp;{' '}
                    {metricsWS.rx_mbps ? getBeforeSpace(formatDataRate(metricsWS.rx_mbps)) : '0'}
                    <i className="text-xs">
                      &nbsp;{' '}
                      {metricsWS.rx_mbps
                        ? getAfterSpace(formatDataRate(metricsWS.rx_mbps))
                        : 'Mb/s'}{' '}
                      <DownloadIcon className="text-purple-500 -ml-1" fontSize="xs" />
                    </i>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default HeaderDeviceDetails
