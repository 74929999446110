import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { capitalizeFirstLetter } from '../../../helpers/string'
import { update } from '../../../store/distributions/getOne'
import { fullDateFormat } from '../../../helpers/date'
import Action from '../actions/row/Actions'

const DistributionRow = ({ cols, row, onDelete }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const onOpen = () => {
    dispatch(update(row))
    navigate(`/firmware/${row.id}`)
  }
  return (
    <tr className="border-b text-o dark:text-white dark:bg-slate-800 hover:bg-gray-100 dark:hover:bg-slate-700">
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
        <input id="default-checkbox" type="checkbox" className="w-4 h-4 " disabled />
      </td>
      {cols.map((col, key) => {
        if (col.key) {
          return (
            <td key={key} className="text-sm font-light px-6 py-4 whitespace-nowrap ">
              {col.key === 'version' ? (
                <span className="rounded-lg bg-blue-500 text-white py-1 px-2">{row[col.key]}</span>
              ) : (
                capitalizeFirstLetter(row[col.key])
              )}
            </td>
          )
        } else if (col.label === 'Actions') {
          return (
            <td key={key} className="text-sm font-light px-6 py-4 whitespace-nowrap ">
              <div className="flex items-stretch">
                {<Action row={row} /*onDelete={(row) => onDelete(row)}*/ onOpen={onOpen} />}
              </div>
            </td>
          )
        } else if (col.label === 'Created At') {
          return (
            <td key={key} className="text-sm font-light px-6 py-4 whitespace-nowrap ">
              {fullDateFormat(row.createdAt)}
            </td>
          )
        } else {
          return null
        }
      })}
    </tr>
  )
}

export default DistributionRow
