import DownloadIcon from '@mui/icons-material/Download'
import Cached from '@mui/icons-material/Cached'
import Switch from '../../common/switch/Switch'
import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import LogsView from '../logs/LogsView'

const LogDevice = ({ logs, onRefresh, autoRefresh, setAutoRefresh, loading }) => {
  const { t } = useTranslation()
  const [time, setTime] = useState('1d')

  const exportLogs = () => {
    if (logs.data && logs.data.length) {
      const data = logs.data.map(
        (el, index) =>
          `${('000' + (index + 1)).slice(-4)} ${el.date || 'N/A'}  ${el.msg || 'N/A'}  [${
            el.labels.ipAddress || 'N/A'
          }]  [${el.labels.level || 'N/A'}]`
      )
      const file = new Blob([data.join('\n')], { type: 'text/plain' })

      // update the download link state
      const element = document.createElement('a')
      element.href = URL.createObjectURL(file)
      element.download = 'device-lifi-' + Date.now() + '.log'
      // simulate link click
      document.body.appendChild(element)
      element.click()
    }
  }

  useEffect(() => {
    let intervalId

    if (autoRefresh) {
      intervalId = setInterval(() => {
        onRefresh(time, false)
      }, 5000)
    }
    return () => {
      clearInterval(intervalId)
    }
  }, [autoRefresh, time])

  return (
    <div className="h-full overflow-hidden border rounded-md shadow-md">
      <div className="flex items-center justify-between p-2">
        <div className="text-lg text-gray-700 dark:text-white">{t('LOGS')}</div>
        <div className="flex space-x-5 items-center">
          <Switch
            title={t('AUTO_REFRESH')}
            value={autoRefresh}
            onChange={(v) => {
              setAutoRefresh(v)
            }}
          />
          <select
            onChange={(e) => {
              setTime(e.target.value)
              onRefresh(e.target.value)
            }}
            value={time}
            aria-label="Select day"
            className="focus:outline-none focus:ring bg-gray-50 border border-gray-200 text-gray-900 lg:text-sm text-xs rounded-lg focus:ring-blue-500/50 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white w-auto"
          >
            <option value="1d">1 {t('DAY')}</option>
            <option value="2d">2 {t('DAYS')}</option>
            <option value="3d">3 {t('DAYS')}</option>
            <option value="7d">{t('LAST_WEEK')}</option>
          </select>
          <button
            onClick={() => onRefresh(time, true)}
            aria-label="Refresh logs"
            disabled={!logs.data.length}
            className={`${
              !logs.data.length
                ? 'cursor-not-allowed opacity-60'
                : 'cursor-default hover:bg-gray-100 dark:hover:bg-slate-600 focus:outline-none focus:ring-2 focus:ring-offset-2'
            } inline-flex justify-center p-1.5 border border-gray-200 shadow-sm font-medium rounded-full text-white  items-center`}
          >
            <Cached
              className={`${loading ? 'animate-spin' : ''} text-gray-600 dark:text-gray-200`}
            />
          </button>
          <button
            onClick={exportLogs}
            aria-label="Download logs as a file"
            disabled={!logs.data.length}
            className={`${
              !logs.data.length
                ? 'cursor-not-allowed opacity-60'
                : 'cursor-default hover:bg-gray-100 dark:hover:bg-slate-600 focus:outline-none focus:ring-2 focus:ring-offset-2'
            } inline-flex justify-center p-1.5 border border-gray-200 shadow-sm font-medium rounded-full text-white items-center`}
          >
            <DownloadIcon className="text-gray-600 dark:text-gray-200 animate-down" />
          </button>
        </div>
      </div>
      <div className="h-full">
        <LogsView logs={logs} loading={loading} autoRefresh={autoRefresh} />
      </div>
    </div>
  )
}

export default LogDevice
