import { useTranslation } from 'react-i18next'
import Switch from '../../../common/switch/Switch'
import DownloadCsr from '../../../common/config/DownloadCsr'
import UploadCert from '../../../common/config/UploadCert'

const AccessPoint = ({ data, handleChange, id, setUploadError, uploadError, changes }) => {
  const { t } = useTranslation()

  return (
    <form className="w-full flex flex-col space-y-4">
      <Switch
        title={`802.1X: EAP-TLS ${changes['configs.security.ap.dot1x.active'] ? '●' : ''}`}
        value={data?.dot1x?.active}
        description={t('802.1X_DESC')}
        onChange={(v) => handleChange('configs.security.ap.dot1x.active', v)}
      />

      {data?.dot1x?.active && (
        <div className="w-full space-y-3">
          <DownloadCsr app={'dot1x'} id={id} />
          <UploadCert
            setUploadError={setUploadError}
            uploadError={uploadError?.dot1x}
            app={'dot1x'}
            id={id}
          />
        </div>
      )}
      <hr />
      <p>TLS</p>
      <DownloadCsr app={'tls'} id={id} />
      <UploadCert
        setUploadError={setUploadError}
        uploadError={uploadError?.tls}
        app={'tls'}
        id={id}
      />
    </form>
  )
}

export default AccessPoint
