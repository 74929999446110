/* eslint-disable no-undefined */
import { useDispatch, useSelector } from 'react-redux'
import { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { capitalizeFirstLetter } from '../../helpers/string'
import { getAllWidgets } from '../../store/widgets/getAll'
import { getOneWidgets } from '../../store/widgets/getOne'
import { editCompany } from '../../store/compagnies/edit'
import CheckBox from '../../common/fields/CheckBox'
import GlobalModal from '../modals/Layout'
import Spinner from '../spinner/Spinner'

const EditCompany = ({ data, setShowModalEdit }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [choices, setChoices] = useState([])
  const prevAllWidget = useRef()
  const prevOneWidget = useRef()
  const widgets = useSelector((state) => state.widgets)
  const organizationWidget = useSelector((state) => state.oneWidget)
  const [dataCompany, setDataCompany] = useState({
    data: {
      name: data.name || '',
      phone: data.phone || '',
      email: data.email || '',
      address: data.address || ''
    },
    id: data.organization_id
  })
  const [error, setError] = useState(false)
  useEffect(() => {
    if (!widgets.data.length && !widgets.loading) {
      dispatch(getAllWidgets())
    } else {
      createChoices()
    }
    dispatch(getOneWidgets(data.organization_id))
  }, [])
  useEffect(() => {
    if (prevAllWidget.current && !widgets.loading && !choices.length) {
      createChoices()
    }
    prevAllWidget.current = widgets.loading
  }, [widgets.loading])
  useEffect(() => {
    if (prevOneWidget.current && !organizationWidget.loading) {
      getItems()
    }
    prevOneWidget.current = organizationWidget.loading
  }, [organizationWidget.loading])
  const getItems = () => {
    let items = []
    if (organizationWidget.data && organizationWidget.data.items) {
      items = organizationWidget.data.items.map((el) => el.widget_item_id)
    }
    setDataCompany((prev) => ({ ...prev, data: { ...prev.data, items } }))
  }
  const createChoices = () => {
    const list = []
    for (const item of widgets.data) {
      list.push({ value: item.widget_item_id, label: capitalizeFirstLetter(item.name) })
    }
    setChoices(list)
  }
  const checkData = () => {
    if (
      dataCompany.data.name &&
      Portable_Valide(dataCompany.data.phone) &&
      Mail_Valide(dataCompany.data.email)
    ) {
      setError(false)
      dispatch(editCompany(dataCompany))
      setShowModalEdit(false)
    } else {
      setError(true)
    }
  }
  function Portable_Valide(PortableTest) {
    var regex = new RegExp(/^\+?[0-9\s-]+$/)

    if (regex.test(PortableTest)) {
      return true
    } else {
      return false
    }
  }
  function Mail_Valide(EmailTest) {
    var reg = new RegExp(
      '^[a-z0-9]+([_|.|-]{1}[a-z0-9]+)*@[a-z0-9]+([_|.|-]{1}[a-z0-9]+)*[.]{1}[a-z]{2,6}$',
      'i'
    )
    if (reg.test(EmailTest)) {
      return true
    } else {
      return false
    }
  }
  return (
    <GlobalModal
      buttons={[
        {
          title: t('CANCEL'),
          action: () => {
            setShowModalEdit(false)
          },
          type: 'secondary'
        },
        {
          title: t('CONFIRM'),
          action: () => {
            checkData()
          },
          type: 'primary'
        }
      ]}
      title={`${t('EDIT')} ${t('COMPANY')} : ${data.name}`}
    >
      <div className="px-10">
        {organizationWidget.loading || widgets.loading ? (
          <div className="absolute w-full h-full z-50 top-0 left-0">
            <Spinner version={1} isContainer />
          </div>
        ) : (
          <>
            <div className="flex justify-center h-12">
              {error && (
                <div className="w-1/2 flex items-center p-3 justify-center border border-red-400 bg-red-50 rounded-lg text-red-500">
                  {t('ERROR_FORM')}
                </div>
              )}
            </div>
            <div className="py-2 ">
              <label className="block text-sm font-medium text-gray-700 dark:text-white">
                {t('COMPANY_NAME')}
              </label>
              <input
                defaultValue={data.name || ''}
                onChange={(e) => {
                  e.target.value
                    ? setDataCompany((prev) => ({
                        ...prev,
                        data: { ...prev.data, name: e.target.value }
                      }))
                    : setError(true)
                }}
                placeholder="name"
                type="email"
                className={`mt-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white form-input bg-secondary text-primary block w-full py-2 px-3 border rounded-md shadow-sm focus:outline-none`}
              />
            </div>
            <div className="py-2 ">
              <label className="block text-sm font-medium text-gray-700 dark:text-white">
                {t('COMPANY_PHONE')}
              </label>
              <input
                defaultValue={data.phone || ''}
                onChange={(e) => {
                  e.target.value
                    ? setDataCompany((prev) => ({
                        ...prev,
                        data: { ...prev.data, phone: e.target.value }
                      }))
                    : setError(true)
                }}
                placeholder="Organization phone"
                type="email"
                className={`mt-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white form-input bg-secondary text-primary block w-full py-2 px-3 border rounded-md shadow-sm focus:outline-none`}
              />
            </div>
            <div className="py-2 ">
              <label className="block text-sm font-medium text-gray-700 dark:text-white">
                {t('COMPANY_EMAIL')}
              </label>
              <input
                defaultValue={data.email || ''}
                onChange={(e) => {
                  e.target.value
                    ? setDataCompany((prev) => ({
                        ...prev,
                        data: { ...prev.data, email: e.target.value }
                      }))
                    : setError(true)
                }}
                placeholder={t('COMPANY_EMAIL')}
                type="email"
                className={`mt-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white form-input bg-secondary text-primary block w-full py-2 px-3 border rounded-md shadow-sm focus:outline-none`}
              />
            </div>
            <div className="py-2 ">
              <label className="block text-sm font-medium text-gray-700 dark:text-white">
                {t('COMPANY_ADDRESS')}
              </label>
              <input
                defaultValue={data.address || ''}
                onChange={(e) => {
                  e.target.value
                    ? setDataCompany((prev) => ({
                        ...prev,
                        data: { ...prev.data, address: e.target.value }
                      }))
                    : setError(true)
                }}
                placeholder={t('COMPANY_ADDRESS')}
                type="email"
                className={`mt-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white form-input bg-secondary text-primary block w-full py-2 px-3 border rounded-md shadow-sm focus:outline-none`}
              />
            </div>
            {/* {dataCompany.data.items !== undefined && (
              <div className="">
                <CheckBox
                  choices={choices}
                  label="Widgets"
                  values={dataCompany.data.items}
                  onChange={(val) =>
                    setDataCompany((prev) => ({
                      ...prev,
                      data: {
                        ...prev.data,
                        items: val
                      }
                    }))
                  }
                  disposition="2"
                />
              </div>
            )} */}
          </>
        )}
      </div>
    </GlobalModal>
  )
}

export default EditCompany
