import { capitalizeFirstLetter } from '../../../helpers/string'
import { fullDateFormat } from '../../../helpers/date'
import DeleteIcon from '@mui/icons-material/Delete'

const schemaColors = {
  ready: 'text-gray-700 bg-gray-100',
  running: 'text-green-700 bg-green-100',
  finished: 'text-blue-700 bg-blue-100',
  paused: 'text-orange-700 bg-orange-100',
  creating: 'text-gray-700 bg-gray-100'
}

const Row = ({ cols, row, onDelete, index }) => {
  return (
    <tr className="border-b text-o dark:text-white dark:bg-slate-800 hover:bg-gray-100 dark:hover:bg-slate-700">
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
        <input
          aria-label="Select update"
          id={'update_' + row.id}
          type="checkbox"
          className="w-4 h-4 "
          disabled
        />
      </td>
      {cols.map((col, key) => {
        if (col.key === 'status') {
          return (
            <td key={key} className="text-sm font-light px-6 py-4 ">
              <div
                className={`${
                  schemaColors[row[col.key]]
                } px-0.5 py-1 flex justify-center rounded-lg shadow-sm`}
              >
                {capitalizeFirstLetter(row[col.key])}
              </div>
            </td>
          )
        } else if (col.key) {
          return (
            <td key={key} className="text-sm font-light px-6 py-4 whitespace-nowrap ">
              {row[col.key]}
            </td>
          )
        } else if (col.label === 'Actions') {
          return (
            <td key={key} className="text-sm font-light px-6 py-4 whitespace-nowrap ">
              <div className="flex items-stretch">
                <button
                  aria-label="Delete update"
                  onClick={() => onDelete(row.id)}
                  className="rounded-full flex items-center p-2 text-gray-700 hover:bg-red-100 hover:text-red-500 dark:text-white dark:hover:text-red-500"
                >
                  <DeleteIcon />
                </button>
              </div>
            </td>
          )
        } else if (col.label === 'Created_At') {
          return (
            <td key={key} className="text-sm font-light px-6 py-4 whitespace-nowrap ">
              {fullDateFormat(row.createdAt)}
            </td>
          )
        } else if (col.label === 'Start_At') {
          return (
            <td key={key} className="text-sm font-light px-6 py-4 whitespace-nowrap ">
              {fullDateFormat(row.startAt)}
            </td>
          )
        } else if (col.label === 'Scheduled for') {
          return (
            <td key={key} className="text-sm font-light px-6 py-4 whitespace-nowrap ">
              {/* {row.description ? fullDateFormat(row.description) : 'N/A'} */} N/A
            </td>
          )
        }
      })}
    </tr>
  )
}

export default Row
