import Avatar from 'react-avatar'

import { useSelector } from 'react-redux'

import { capitalizeFirstLetter } from '../../../helpers/string'
import { fullDateFormat } from '../../../helpers/date'
import { useTranslation } from 'react-i18next'
import Action from '../actions/row/Actions'

const schemaColors = {
  New: 'text-blue-700 bg-blue-100',
  Active: 'text-green-700 bg-green-100',
  Blocked: 'text-red-700 bg-red-100'
}
const Row = ({ cols, row, onDelete, onEdit, onLink, index }) => {
  const { t } = useTranslation()
  const currentUser = useSelector((state) => state.currentUser.data)
  return (
    <tr className="border-b dark:text-white dark:bg-slate-800 hover:bg-gray-100 dark:hover:bg-slate-700">
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
        <input
          aria-label="Select user"
          id={'user_' + row.user_id}
          type="checkbox"
          className="w-4 h-4 "
          disabled
        />
      </td>
      {cols.map((col, key) => {
        if (col.key) {
          return (
            <td key={key} className="text-sm font-light px-6 py-4 whitespace-nowrap">
              {col.key !== 'created_at' && col.key !== 'updated_at'
                ? row[col.key]
                : fullDateFormat(row[col.key])}
            </td>
          )
        } else if (col.label === 'Action') {
          return (
            <td key={key} className="text-sm font-light px-6 py-4 whitespace-nowrap ">
              {currentUser.user_id !== row.user_id ? (
                <Action row={row} onDelete={(row) => onDelete(row.user_id)} onEdit={onEdit} />
              ) : null}
            </td>
          )
        } else if (col.label === 'Name') {
          const fullName = `${capitalizeFirstLetter(row.first_name)} ${capitalizeFirstLetter(
            row.last_name
          )}`
          return (
            <td key={key} className="text-sm font-light px-6 py-4 whitespace-nowrap ">
              <div className="flex space-x-2">
                <Avatar
                  name={fullName}
                  round
                  size="36"
                  className="h-6 inline-block  ring-white cursor-pointer"
                />
                <div className="flex items-center justify-center">{fullName}</div>
              </div>
            </td>
          )
        } else if (col.label === 'Status') {
          const status = row.new ? 'New' : row.blocked ? 'Blocked' : 'Active'
          return (
            <td
              key={key}
              className="text-sm font-light px-6 py-4 whitespace-nowrap items-center flex "
            >
              {row.reset_link ? (
                <div
                  className=" bg-orange-100 px-2 py-1 flex justify-center rounded-lg relative cursor-pointer"
                  onClick={() => {
                    onLink(row.reset_link)
                  }}
                >
                  {t('RESET')}
                  <span className="absolute flex h-3 w-3 -right-1 -top-1">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-orange-400 opacity-75" />
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-orange-500" />
                  </span>
                </div>
              ) : (
                <div
                  className={`${schemaColors[status]} px-2 py-1 flex justify-center rounded-lg relative cursor-pointer`}
                  onClick={() => {
                    if (status === 'New') onLink(row.password)
                  }}
                >
                  {status === 'New' && (
                    <span className="absolute flex h-3 w-3 -right-1 -top-1">
                      <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75" />
                      <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500" />
                    </span>
                  )}
                  {t(status.toUpperCase())}
                </div>
              )}
            </td>
          )
        }
      })}
    </tr>
  )
}

export default Row
