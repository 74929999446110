import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { switchOganization } from '../../store/currentUser/auth'
import Notification from '../notification/Notification'
import { sendMessage } from '../../utils/socket'
import MenuIcon from '@mui/icons-material/Menu'
import logo from '../../assets/img/logo.png'
import Dropdown from './Dropdown'
import Profile from './Profile'
import { useTranslation } from 'react-i18next'

const NavBar = ({ burgerToogle, alerts }) => {
  const dashboard = useSelector((state) => state.dashboard)
  const currentDashbord = useRef()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.currentUser)
  const [currentOrganization, setCurrentOrganization] = useState()
  const [hasALertNotResolved, setHasALertNotResolved] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    setHasALertNotResolved(checkAlert(alerts))
  }, [alerts])
  const checkAlert = (data) => {
    let found = false
    let i = 0
    while (!found && i < data.length) {
      if (!data[i].resolved_by) {
        found = true
        break
      }
      i++
    }
    return found
  }
  const deleteAlert = (device_alert_id) => {
    const data = {
      device_alert_id,
      deleted_by: user.data.user_id
    }
    sendMessage(`alert/actions`, data)
  }
  const resolveAlert = (device_alert_id) => {
    const data = {
      device_alert_id,
      resolved_by: user.data.user_id
    }
    sendMessage(`alert/actions`, data)
  }
  useEffect(() => {
    if (
      currentDashbord.current &&
      !dashboard.loading &&
      dashboard.data &&
      dashboard.data.organizations &&
      dashboard.data.organizations.length > 1
    ) {
      const current = dashboard.data.organizations.find(
        (el) => el.organization_id == user.data.organization_id
      )
      setCurrentOrganization(current)
    }
    currentDashbord.current = dashboard.loading
  }, [dashboard])
  return (
    <>
      <nav className="fixed w-full z-10 bg-white px-3 py-3 border-b border-gray-200 dark:border-slate-900 dark:bg-slate-700 text-black dark:text-white">
        <div className=" flex items-center justify-between">
          <div className="relative flex items-center space-x-3">
            <div className="block lg:hidden">
              <MenuIcon
                onClick={() => burgerToogle[1](!burgerToogle[0])}
                className="cursor-pointer"
              />
            </div>
            <img src={logo} alt="Oledcomm Logo" width="30px" height="30px" />

            <a
              className="text-xl font-bold leading-relaxed hidden lg:inline-block whitespace-no-wrap"
              href="/"
            >
              LiFi Manager
            </a>
            <b
              className="mr-4 text-xs rounded-lg text-gray-400 font-bold inline-block align-baseline mt-4 border-2 border-gray-400 px-1"
              style={{ marginLeft: 0 }}
            >
              version: 1.0.3
            </b>
          </div>
          {user.data.isSuperAdmin && user.data.supervision_mode && (
            <div className="p-1 bg-orange-100 text-orange-400 rounded-lg border border-orange-400 ">
              {t('SUPERADMIN_GO_BACK')}
              <a
                onClick={() => {
                  dispatch(switchOganization(user.data.super_admin_organization_id))
                }}
                className="ml-4 underline hover:text-orange-500 cursor-pointer"
              >
                {t('CLICK_HERE')}
              </a>
            </div>
          )}
          <div className="flex space-x-5">
            {currentOrganization &&
            !dashboard.loading &&
            dashboard.data.organizations &&
            dashboard.data.organizations.length > 1 ? (
              <div className="nav-item flex items-center">
                <Dropdown
                  organizations={dashboard.data.organizations}
                  currentOrganization={currentOrganization}
                />
              </div>
            ) : null}
            {/*<div className="nav-item flex items-center">
              <Toggle />
            </div>*/}
            <div className="nav-item flex items-center">
              <Notification
                alerts={alerts}
                onDelete={deleteAlert}
                onResolve={resolveAlert}
                hasALertNotResolved={hasALertNotResolved}
              />
            </div>
            <div className="nav-item flex items-center">
              <Profile />
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}
export default NavBar
