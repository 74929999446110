import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { initiateSocket, disconnectSocket, subscribeToUpdateDeviceConfig } from '../utils/socket'
import HeaderDeviceDetails from '../components/deviceDetail/HeaderDeviceDetails'
import GlobalDeviceInfo from '../components/deviceDetail/GlobalDeviceInfo'
import PropertiesPanel from '../components/deviceDetail/PropertiesPanel'
import { startEndDates, dateToTimestamp } from '../helpers/date'
import LogDevice from '../components/deviceDetail/LogDevice'
import { success } from '../store/requestInfo/requestInfo'
import { getOne, update } from '../store/devices/getOne'
import StateInfo from '../common/stateInfo/StateInfo'
import Spinner from '../components/spinner/Spinner'
import { getLogs } from '../store/logs/deviceLogs'

const DeviceDetails = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const [showPropertiesPanel, setShowPropertiesPanel] = useState({ show: false, willClose: false })
  const [cacheLog, setCacheLog] = useState({ data: [], loading: false })
  const device = useSelector((state) => state.device)
  const logs = useSelector((state) => state.deviceLogs)
  const [autoRefresh, setAutoRefresh] = useState(false)

  useEffect(() => {
    if (
      !device.loading &&
      (!device.data.device_hardware_id || device.data.device_hardware_id !== id)
    ) {
      dispatch(getOne(id)).then((data) => {
        initiateSocket()
        subscribeToUpdateDeviceConfig(data.payload?.data?.device_uuid, (msg) => {
          dispatch(success('DEVICE_CONFIGURATION_SUCCESS'))
          dispatch(update({ data: msg.data }))
        })
      })
    }
    return () => {
      disconnectSocket()
      setCacheLog({ data: [], loading: true })
    }
  }, [id])

  useEffect(() => {
    if (!device.loading && device.data.device_uuid) {
      fetchLogs()
    }
  }, [device.loading])

  useEffect(() => {
    if (!logs.loading) {
      if (autoRefresh) {
        setCacheLog((prevCacheLog) => {
          const newCacheLog = [...logs.data, ...prevCacheLog.data]
          return { data: newCacheLog, loading: false }
        })
      } else {
        setCacheLog({ data: logs.data, loading: false })
      }
    }
  }, [logs.loading])

  const fetchLogs = (interval = '1d', refresh = true) => {
    if (refresh) {
      if (device.data && device.data.device_uuid && !logs.loading) {
        const dates = startEndDates(interval)
        const query = `{device_uuid="${device.data.device_uuid}"}|= ""`
        dispatch(
          getLogs({
            startDate: dateToTimestamp(dates.startDate),
            endDate: dateToTimestamp(dates.endDate),
            query,
            device_uuid: device.data.device_uuid
          })
        )
      }
    } else {
      const now = new Date()
      const lastLog = new Date(cacheLog?.data[0]?.date)
      lastLog.setSeconds(lastLog.getSeconds() + 1)

      const query = `{device_uuid="${device.data.device_uuid}"}|= ""`

      dispatch(
        getLogs({
          startDate: dateToTimestamp(lastLog),
          endDate: dateToTimestamp(now),
          query,
          device_uuid: device.data.device_uuid
        })
      )
    }
  }
  return (
    <>
      {showPropertiesPanel.show && (
        <PropertiesPanel
          handleClose={() => {
            setShowPropertiesPanel((prev) => ({ ...prev, willClose: true }))
            setTimeout(() => {
              setShowPropertiesPanel((prev) => ({ ...prev, show: false }))
            }, 500)
          }}
          device={device.data}
          willClose={showPropertiesPanel.willClose}
        />
      )}
      {device.loading || !device?.data?.device_hardware_id ? (
        <div className=" w-full h-full ">
          <Spinner version={1} isContainer />
        </div>
      ) : device.error ? (
        device.error.code === 403 ? (
          <div className="h-full flex items-center justify-center">
            <StateInfo type="warning" description={device.error.message} />
          </div>
        ) : (
          <div className="h-full flex items-center justify-center">
            <StateInfo
              type="error"
              description={typeof device.error === 'object' ? device.error.message : device.error}
            />
          </div>
        )
      ) : (
        <div className="h-screen w-full p-4 lg:grid grid-cols-2 grid-rows-4 gap-4 flex-1 lg:overflow-hidden">
          <div className="w-full col-span-2">
            <HeaderDeviceDetails device={device} />
          </div>
          <div className="row-span-3">
            <GlobalDeviceInfo
              device={device.data}
              onEdit={() => setShowPropertiesPanel({ show: true, willClose: false })}
            />
          </div>
          <div className="row-span-3">
            <LogDevice
              device={device}
              logs={cacheLog}
              onRefresh={fetchLogs}
              autoRefresh={autoRefresh}
              setAutoRefresh={setAutoRefresh}
              loading={logs.loading}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default DeviceDetails
