import { capitalizeFirstLetter } from '../../../helpers/string'
import { fullDateFormat } from '../../../helpers/date'
import PersonIcon from '@mui/icons-material/Person'
import Action from '../actions/row/Actions'
import ReactTooltip from 'react-tooltip'

const Row = ({ cols, row, onDelete, onEdit, index }) => {
  return (
    <tr className="border-b dark:text-white dark:bg-slate-800 hover:bg-gray-100 dark:hover:bg-slate-700">
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
        <input id="default-checkbox" type="checkbox" className="w-4 h-4 " disabled />
      </td>
      {cols.map((col, key) => {
        if (col.key) {
          return (
            <td key={key} className="text-sm font-light px-6 py-4 whitespace-nowrap">
              {col.key === 'created_at'
                ? fullDateFormat(row[col.key])
                : col.key === 'roles'
                ? capitalizeFirstLetter(row[col.key].toString().replaceAll('_', ' '))
                : row[col.key]}
            </td>
          )
        } else {
          if (col.label === 'Members') {
            return (
              <td key={key} className="text-sm font-light px-6 py-4 whitespace-nowrap ">
                <div className="flex items-stretch">
                  <PersonIcon fontSize="small" /> ● &nbsp;
                  <div>
                    <button
                      data-tip=""
                      data-for={`info-${row.group_id}`}
                      data-class="react-component-tooltip"
                    >
                      {row.users ? row.users.length : 0}
                    </button>
                    <ReactTooltip id={`info-${row.group_id}`}>
                      <ul>
                        {row.users.map((el, index) => {
                          return (
                            <li key={index} className="text-sm text-secondary">
                              {el.first_name}&nbsp;{el.last_name}
                            </li>
                          )
                        })}
                      </ul>
                    </ReactTooltip>
                  </div>
                </div>
              </td>
            )
          }
          if (col.label === 'Read_permission') {
            return (
              <td key={key} className="text-sm font-light px-6 py-4 whitespace-nowrap ">
                <div className="rounded-md border border-gray-200 w-fit">
                  <button
                    data-tip=""
                    data-for={`info-${row.device_group_id}`}
                    data-class="react-component-tooltip"
                    className="p-1 px-2"
                  >
                    {row.read?.length}
                  </button>
                  <ReactTooltip id={`info-${row.device_group_id}`}>
                    <ul>
                      {row.read?.map((el, index) => {
                        return <p key={index}>{el.name}</p>
                      })}
                    </ul>
                  </ReactTooltip>
                </div>
              </td>
            )
          }
          if (col.label === 'Edit_permission') {
            return (
              <td key={key} className="text-sm font-light px-6 py-4 whitespace-nowrap ">
                <div className="rounded-md border border-gray-200 w-fit">
                  <button
                    data-tip=""
                    data-for={`info-edit-${row.device_group_id}`}
                    data-class="react-component-tooltip"
                    className="p-1 px-2"
                  >
                    {row.edit?.length}
                  </button>
                  <ReactTooltip id={`info-edit-${row.device_group_id}`}>
                    <ul>
                      {row.edit?.map((el, index) => {
                        return <p key={index}>{el.name}</p>
                      })}
                    </ul>
                  </ReactTooltip>
                </div>
              </td>
            )
          }
          if (col.label === 'Actions') {
            return (
              <td key={key} className="text-sm font-light px-6 py-4 whitespace-nowrap ">
                <Action
                  row={row}
                  onDelete={(row) => onDelete(row.device_group_id, row.name)}
                  onEdit={onEdit}
                />
              </td>
            )
          }
        }
      })}
    </tr>
  )
}

export default Row
