import AssignmentIcon from '@mui/icons-material/Assignment'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import OpenWithIcon from '@mui/icons-material/OpenWith'
import PersonIcon from '@mui/icons-material/Person'
import GroupIcon from '@mui/icons-material/Group'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import ChartConnectedDevices from '../components/Dashboard/ChartConnectedDevices'
import TrainWidgetV1 from '../components/Dashboard/TrainWidgetV1'
import useScreenSize from '../utils/useScreenSize'
import DevicesStats from '../components/Dashboard/DevicesStats'
import { Responsive, WidthProvider } from 'react-grid-layout'
import GlobalStats from '../components/Dashboard/GlobalStats'
import ChartLogs from '../components/Dashboard/ChartLogs'
import { useTranslation } from 'react-i18next'
import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import {
  initiateSocket,
  subscribeToAllMetrics,
  disconnectSocket,
  sendMessage
} from '../utils/socketAuditing'

import '/node_modules/react-grid-layout/css/styles.css'
import '/node_modules/react-resizable/css/styles.css'
import '../assets/css/main.css'

const ResponsiveReactGridLayout = WidthProvider(Responsive)

const Dashboard = ({ alerts }) => {
  const dashboard = useSelector((state) => state.dashboard)
  const { t } = useTranslation()
  const [metrics, setMetrics] = useState([])
  const [metricsLoading, setMetricsLoading] = useState(true)
  const [isGrab, setIsGrab] = useState(false)
  const [mounted, setMounted] = useState(false)
  const [socket, setSocket] = useState(null)
  const { width, height } = useScreenSize()

  const [layoutLg, setLayoutLg] = useState(
    localStorage.getItem('layoutLg')
      ? JSON.parse(localStorage.getItem('layoutLg'))
      : [
          { i: 'a', x: 0, y: 0, w: 1, h: 1 },
          { i: 'b', x: 1, y: 0, w: 1, h: 1 },
          { i: 'c', x: 2, y: 0, w: 1, h: 1 },
          { i: 'd', x: 3, y: 0, w: 1, h: 1 },
          { i: 'e', x: 0, y: 1, w: 2, h: 3 },
          { i: 'f', x: 2, y: 1, w: 2, h: 3 },
          { i: 'g', x: 0, y: 4, w: 4, h: 2 },
          { i: 'h', x: 0, y: 5, w: 4, h: 4 }
        ]
  )
  const [layoutMd, setlayoutMd] = useState(
    localStorage.getItem('layoutMd')
      ? JSON.parse(localStorage.getItem('layoutMd'))
      : [
          { i: 'a', x: 0, y: 0, w: 1, h: 1 },
          { i: 'b', x: 1, y: 0, w: 1, h: 1 },
          { i: 'c', x: 0, y: 1, w: 1, h: 1 },
          { i: 'd', x: 1, y: 1, w: 1, h: 1 },
          { i: 'e', x: 0, y: 2, w: 2, h: 3 },
          { i: 'f', x: 2, y: 2, w: 2, h: 3 },
          { i: 'g', x: 0, y: 1, w: 4, h: 2 },
          { i: 'h', x: 0, y: 2, w: 4, h: 4 }
        ]
  )
  useEffect(() => {
    localStorage.setItem('layoutLg', JSON.stringify(layoutLg))
  }, [layoutLg])

  useEffect(() => {
    localStorage.setItem('layoutMd', JSON.stringify(layoutMd))
  }, [layoutMd])
  const widgets = ['a', 'b', 'c', 'd', 'e', 'f', 'g']
  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    initiateSocket()

    subscribeToAllMetrics((data) => {
      if (metricsLoading) setMetricsLoading(false)
      setMetrics(data.data)
    })
    sendMessage('join/allMetrics')

    return () => {
      disconnectSocket()
    }
  }, [])

  const scrollContainerRef = useRef(null)
  const intervalRef = useRef(null) // Ref to store the interval ID

  const scrollDown = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop += 10
    }
  }
  const scrollUp = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop += -10
    }
  }

  const startScrolling = (direction) => {
    if (!intervalRef.current) {
      if (direction === 'up') {
        intervalRef.current = setInterval(scrollUp, 10)
      } else {
        intervalRef.current = setInterval(scrollDown, 10)
      }
    }
  }

  const stopScrolling = () => {
    // Clear the interval and reset the reference
    clearInterval(intervalRef.current)
    intervalRef.current = null
  }

  return (
    <div className="h-full overflow-y-auto" ref={scrollContainerRef}>
      <div className="">
        {height < 900 ||
          (width < 1300 && (
            <div className="bg-blue-50 w-16 absolute bottom-20 right-10 z-50 flex flex-col text-black rounded-lg">
              <button
                className="active:bg-blue-300 hover:bg-blue-100 rounded-lg"
                onMouseDown={() => startScrolling('up')}
                onMouseUp={stopScrolling}
                onMouseLeave={stopScrolling}
              >
                <KeyboardArrowUpIcon fontSize="large" />
              </button>
              <button
                className="active:bg-blue-300 hover:bg-blue-100 rounded-lg"
                onMouseDown={() => startScrolling('down')}
                onMouseUp={stopScrolling}
                onMouseLeave={stopScrolling}
              >
                <KeyboardArrowDownIcon fontSize="large" color="white" />
              </button>
            </div>
          ))}
        <ResponsiveReactGridLayout
          rowHeight={130}
          cols={{ lg: 4, md: 2, sm: 2, xs: 2, xxs: 2 }}
          measureBeforeMount={false}
          useCSSTransforms={mounted}
          preventCollision={false}
          isResizable={false}
          isDraggable={true}
          draggableHandle=".cancelSelectorName"
          layouts={{ lg: layoutLg, md: layoutMd }}
          onLayoutChange={(layout, layouts) => {
            setLayoutLg(layouts.lg)
            setlayoutMd(layouts.md)
          }}
        >
          {widgets.map((i) => (
            <div className="touch-none" key={i}>
              <button
                className={`w-full h-full absolute z-50 cancelSelectorName ${
                  isGrab ? 'cursor-grabbing' : 'cursor-grab'
                }`}
                onMouseDown={() => setIsGrab(true)}
                onMouseUp={() => setIsGrab(false)}
                aria-label={t('MOVE_WIDGET')}
              >
                <OpenWithIcon className="text-gray-300 absolute z-50 top-0 right-0" />
              </button>
              {i === 'a' && (
                <GlobalStats
                  title={t('TOTAL_USERS')}
                  number={dashboard.data.userCount}
                  description={t('TOTAL_USERS_DESC')}
                  icon={<PersonIcon fontSize="large" />}
                  color="blue"
                  loading={dashboard.loading}
                />
              )}
              {i === 'b' && (
                <GlobalStats
                  title={t('TOTAL_GROUPS')}
                  number={dashboard.data.groupCount}
                  description={t('TOTAL_GROUPS_DESC')}
                  icon={<GroupIcon fontSize="large" />}
                  color="blue"
                  loading={dashboard.loading}
                />
              )}
              {i === 'c' && (
                <GlobalStats
                  title={t('TOTAL_DEVICES')}
                  number={dashboard.data.deviceCount}
                  description={t('TOTAL_DEVICES_DESC')}
                  icon={<LightbulbIcon fontSize="large" />}
                  color="red"
                  loading={dashboard.loading}
                />
              )}
              {i === 'd' && (
                <GlobalStats
                  title={t('TOTAL_LOGS')}
                  number={dashboard.data.logsCount}
                  description={t('TOTAL_LOGS_DESC')}
                  icon={<AssignmentIcon fontSize="large" />}
                  color="blue"
                  loading={dashboard.loading}
                />
              )}
              {i === 'e' && <ChartConnectedDevices metrics={metrics} loading={metricsLoading} />}
              {i === 'f' && <DevicesStats metrics={metrics} />}
              {i === 'g' && <ChartLogs />}
            </div>
          ))}
        </ResponsiveReactGridLayout>
      </div>
    </div>
  )
}

export default Dashboard
